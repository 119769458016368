import {
  Button,
  Grid,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import FlatList from 'flatlist-react';
import React, { useEffect, useState, useCallback } from 'react';
import { format, parse, parseISO } from 'date-fns';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import logoImg from '../../../assets/lagoinha_preto.png';
import { useNetwork } from '../../../hooks/network';
import { Popup, UseTable, ActionDialog } from '../../../components';
import {
  useStyles,
  ItemLista,
  ItemBotoes,
  BackgroundErro,
  ContainerErro,
  ContentErro,
  AnimationContainerErro,
} from './styles';
import BatizanteForm from './BatizanteForm';
import api from '../../../services/api';
import { decrypt, encrypt, encryptUUID } from '../../../services/crypt';
import { useToast } from '../../../hooks/toast';
import { formatarCelular } from '../../../utils/formatarCelular';
import ActionButton from '../../../components/ActionButton';
import { useGeolocation } from '../../../hooks/geolocation';
import { usePermission } from '../../../hooks/permission';
import VoluntarioForm from './VoluntarioForm';
import removeAccents from '../../../utils/removeAccents';
import './styles.css';

const headCells = [
  { id: 'nome', label: 'Nome' },
  { id: 'data_nascimento', label: 'Data Nascimento' },
  { id: 'celular', label: 'Celular', disableSorting: true },
  { id: 'email', label: 'E-mail', disableSorting: true },
  { id: 'estado_civil', label: 'Estado Civil' },
  { id: 'data_batismo', label: 'Data Batismo' },
  { id: 'actions', label: 'Ações', disableSorting: true },
];

const estados_civis = [
  { id: 'casado', title: 'Casado(a)' },
  { id: 'solteiro', title: 'Solteiro(a)' },
  { id: 'amasiado', title: 'Amasiado(a)' },
  { id: 'divorciado', title: 'Divorciado(a)' },
  { id: 'viuvo', title: 'Viúvo(a)' },
];

interface IActionDialog {
  isOpen: boolean;
  title: string;
  subTitle: string;
  onConfirm?: () => void;
}

interface IDiscipulo {
  id: string;
  nome: string;
}

interface IDiscipuloSelect {
  id?: string;
  title?: string;
}

interface ICidade {
  id: string;
  nome: string;
  id_estado: string;
}

interface IEstado {
  id: string;
  nome: string;
  sigla: string;
}

interface IEstadoSelect {
  id?: string;
  title?: string;
  sigla?: string;
}

interface IBatismo {
  id: string;
  descricao: string;
  data_inicio: Date;
  data_termino: Date;
  contato_apos_culto: boolean;
  contato_preparo_batismo: boolean;
  batismo: boolean;
  contato_cafe_com_pastor: boolean;
  cafe_com_pastor: boolean;
  contato_cafe_com_pastor_remanescente: boolean;
  cafe_com_pastor_remanescente: boolean;
}

interface IBatizante {
  id: string;
  nome?: string;
  sexo?: string;
  estado_civil?: string;
  estadoCivilFormatted?: string;
  data_nascimento?: Date;
  dataNascimentoFormatted?: string;
  celular?: string;
  celularFormatted?: string;
  email?: string;
  data_batismo?: Date;
  dataBatismoFormatted?: string;
  cep?: string;
  endereco?: string;
  numero?: number;
  complemento?: string;
  bairro?: string;
  cidade?: string;
  estado?: string;
  ibge?: string;
  latitude?: number;
  longitude?: number;
  batismo_conjunto: boolean;
  fez_casa_paz: boolean;
  id_lider_casa_paz?: string;
  lider_casa_paz?: string;
  discipulo_lider_casa_paz?: IDiscipulo;
  frequenta_celula: boolean;
  id_lider_celula?: string;
  lider_celula?: string;
  nome_celula?: string;
  discipulo_lider_celula?: IDiscipulo;
  observacao?: string;
  contactado_apos_culto?: boolean;
  casa_paz_provisionada?: boolean;
  contactado_preparo_batismo?: boolean;
  batizado?: boolean;
  contactado_cafe_com_pastor?: boolean;
  cafe_com_pastor?: boolean;
  contactado_cafe_com_pastor_remanescente?: boolean;
  cafe_com_pastor_remanescente?: boolean;
  iniciou_casa_paz?: boolean;
  finalizou_casa_paz?: boolean;
  casa_paz_encaminhado_celula?: boolean;
  casa_paz_virou_celula?: boolean;
  id_batismo?: string;
  batismo?: IBatismo;
  foto_url?: string;
  nomes_batismo_conjunto?: string;
  religiao_veio?: string;
  modo_casa_paz?: string;
  dia_horario?: string;
  domingo_manha?: boolean;
  domingo_tarde?: boolean;
  domingo_noite?: boolean;
  segunda_manha?: boolean;
  segunda_tarde?: boolean;
  segunda_noite?: boolean;
  terca_manha?: boolean;
  terca_tarde?: boolean;
  terca_noite?: boolean;
  quarta_manha?: boolean;
  quarta_tarde?: boolean;
  quarta_noite?: boolean;
  quinta_manha?: boolean;
  quinta_tarde?: boolean;
  quinta_noite?: boolean;
  sexta_manha?: boolean;
  sexta_tarde?: boolean;
  sexta_noite?: boolean;
  sabado_manha?: boolean;
  sabado_tarde?: boolean;
  sabado_noite?: boolean;
  batizantes_juntos?: string;
  nome_contato?: string;
  telefone_contato?: string;
  facebook?: string;
  instagram?: string;
  nomeSA?: string;
}

interface IVoluntario {
  nome: string;
  celular: string;
  celularFormatted?: string;
}

const BatizantePreCadastroPage: React.FC = () => {
  const classes = useStyles();
  const { position } = useGeolocation();
  const { isAdmin } = usePermission();
  const { addToast } = useToast();
  const { serverConection } = useNetwork();
  const [voluntario, setVoluntario] = useState<IVoluntario | null>(null);
  const [openPopupVoluntario, setOpenPopupVoluntario] = useState(true);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState<IBatizante[]>([]);
  const [loading, setLoading] = useState(false);
  const [discipulos, setDiscipulos] = useState<IDiscipuloSelect[]>([]);
  const [estados, setEstados] = useState<IEstadoSelect[]>([]);
  const [cidades, setCidades] = useState<ICidade[]>([]);
  const [show] = useState(true);
  // const [show, setShow] = useState(false);
  const [search, setSearch] = useState('');
  const [filterFn, setFilterFn] = useState({
    fn: (items: any) => {
      return items;
    },
  });
  const [actionDialog, setActionDialog] = useState<IActionDialog>({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const [openPopup, setOpenPopup] = useState(false);
  const listar = true;
  const adicionar = true;
  const editar = true;

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    UseTable(records, headCells, filterFn);

  useEffect(() => {
    if (
      position &&
      position.distanceIEQ &&
      position.distanceIEQ <= 50 &&
      show !== true
    ) {
      // setShow(true);
      console.log('1');
    } else if (
      !position ||
      (position.distanceIEQ && position.distanceIEQ > 50 && show === true)
    ) {
      // setShow(false);
      console.log('2');
    }
  }, [position, show]);

  const addVoluntario = (data: IVoluntario, resetForm: any): any => {
    const voluntarioInformado = {
      ...data,
      celularFormatted: data.celular ? formatarCelular(data.celular) : '',
    };
    if (data) {
      const voluntarioAtualizado = {
        nome: data.nome,
        celular: data.celular
          ? data.celular
              .replace('(', '')
              .replace(')', '')
              .replace('-', '')
              .replace(' ', '')
          : '',
      };
      const voluntarioStorage = encrypt(JSON.stringify(voluntarioAtualizado));
      localStorage.setItem('@IEQLagoinha:voluntario', voluntarioStorage);
      setVoluntario(voluntarioInformado);
    } else {
      setVoluntario(null);
    }
    resetForm();
    setOpenPopupVoluntario(false);
    addToast({
      type: 'success',
      title: 'Voluntário informado!',
    });
  };

  useEffect(() => {
    if (serverConection) {
      api
        .get(`/batizantes/precadastro/2b5df6d7-7185-4c96-b376-2417c22bca98`, {
          headers: {
            activity: encrypt('Cadastro de Batizantes'),
          },
        })
        .then(response => {
          const content = decrypt<IBatizante[]>(response.data);
          const batizantesCarregados = content.map(dp => {
            return {
              ...dp,
              data_nascimento: dp.data_nascimento
                ? parse(dp.data_nascimento.toString(), 'yyyy-MM-dd', new Date())
                : undefined,
              dataNascimentoFormatted: dp.data_nascimento
                ? format(
                    parse(
                      dp.data_nascimento.toString(),
                      'yyyy-MM-dd',
                      new Date(),
                    ),
                    'dd/MM/yyyy',
                  )
                : undefined,
              data_batismo: dp.data_batismo
                ? parse(dp.data_batismo.toString(), 'yyyy-MM-dd', new Date())
                : undefined,
              dataBatismoFormatted: dp.data_batismo
                ? format(
                    parse(dp.data_batismo.toString(), 'yyyy-MM-dd', new Date()),
                    'dd/MM/yyyy',
                  )
                : undefined,
              estadoCivilFormatted: estados_civis.find(
                ec => ec.id === dp.estado_civil,
              )?.title,
              celularFormatted: dp.celular ? formatarCelular(dp.celular) : '',
            };
          });
          setRecords(batizantesCarregados);
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'Erro ao listar os Batizantes!',
            description: 'Ocorreu um erro ao tentar listar os Batizantes.',
          });
        });
      setLoading(false);
    }
  }, [addToast, serverConection]);

  useEffect(() => {
    if (serverConection) {
      api
        .get(`/discipulos/batizantes-sala`, {
          headers: {
            activity: encrypt('Listar Discípulos Lider Casa de Paz ou Célula'),
          },
        })
        .then(response => {
          const content = decrypt<IDiscipulo[]>(response.data);
          const listaDiscipulos = content.map(
            e =>
              ({
                id: e.id,
                title: e.nome,
              } as IDiscipuloSelect),
          );
          setDiscipulos(listaDiscipulos);
        })
        .catch();
    }
  }, [serverConection]);

  useEffect(() => {
    if (serverConection) {
      api
        .get(`/cidades/`, {
          headers: {
            activity: encrypt('Listar Cidades'),
          },
        })
        .then(response => {
          const content = decrypt<ICidade[]>(response.data);
          setCidades(content);
        })
        .catch();
    }
  }, [serverConection]);

  useEffect(() => {
    if (cidades.length > 0 && serverConection) {
      api
        .get(`/estados/`, {
          headers: {
            activity: encrypt('Listar Estados'),
          },
        })
        .then(response => {
          const content = decrypt<IEstado[]>(response.data);
          const listaEstados = content.map(
            e =>
              ({
                id: e.id,
                title: `${e.nome} (${e.sigla})`,
                sigla: e.sigla,
              } as IEstadoSelect),
          );
          setEstados(listaEstados);
        })
        .catch();
    }
  }, [cidades, serverConection]);

  const handleSearch = (e: any): any => {
    const { target } = e;
    setFilterFn({
      fn: items => {
        if (target.value === '') return items;
        return items.filter(
          (x: any) =>
            removeAccents(x.nome.toLowerCase()).includes(
              removeAccents(target.value.toLowerCase()),
            ) ||
            removeAccents(x.estado_civil.toLowerCase()).includes(
              removeAccents(target.value.toLowerCase()),
            ) ||
            (x.celular &&
              x.celular.toString().includes(target.value.toLowerCase())) ||
            (x.celular_conjuge &&
              x.celular_conjuge
                .toString()
                .includes(target.value.toLowerCase())) ||
            (x.email &&
              x.email
                .toLowerCase()
                .toString()
                .includes(target.value.toLowerCase())),
        );
      },
    });
    setSearch(target.value.toLowerCase());
  };

  const openInPopup = (item: any): any => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const imprimirEtiqueta = useCallback((dados: any) => {
    // let serverAddress;
    // if (process.env.NODE_ENV === 'development') {
    //   serverAddress = `ws://127.0.0.1:9001`;
    // } else {
    //   serverAddress = `wss://app.tassolucoes.com.br/hub/`;
    // }
    const serverAddress =
      process.env.REACT_APP_HUB_URL || `wss://app.ieqlagoinha.com.br/hub/`;
    const ws = new W3CWebSocket(serverAddress);
    ws.onopen = () => {
      console.log(
        '[IO] Connect Printer Hub => A new connection has been established',
      );
      ws.send(JSON.stringify(dados));
      ws.close();
    };
  }, []);

  const addOrEdit = (
    data: IBatizante,
    resetForm: any,
    batizantesForm?: string[],
  ): any => {
    const {
      id,
      id_batismo,
      nome,
      sexo,
      estado_civil,
      data_nascimento,
      celular,
      email,
      data_batismo,
      cep,
      endereco,
      numero,
      complemento,
      bairro,
      cidade,
      estado,
      ibge,
      batismo_conjunto,
      fez_casa_paz,
      id_lider_casa_paz,
      lider_casa_paz,
      frequenta_celula,
      id_lider_celula,
      lider_celula,
      nome_celula,
      observacao,
      nomes_batismo_conjunto,
      religiao_veio,
      modo_casa_paz,
      dia_horario,
      domingo_manha,
      domingo_tarde,
      domingo_noite,
      segunda_manha,
      segunda_tarde,
      segunda_noite,
      terca_manha,
      terca_tarde,
      terca_noite,
      quarta_manha,
      quarta_tarde,
      quarta_noite,
      quinta_manha,
      quinta_tarde,
      quinta_noite,
      sexta_manha,
      sexta_tarde,
      sexta_noite,
      sabado_manha,
      sabado_tarde,
      sabado_noite,
      batizantes_juntos,
      nome_contato,
      telefone_contato,
      facebook,
      instagram,
    } = data;
    if (batizantes_juntos && batizantes_juntos.split(',').length > 0) {
      batizantes_juntos?.split(',').forEach(item => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const idx = batizantesForm?.findIndex(bt => bt === item);
        // if (idx && idx !== -1) {
        //   console.log(
        //     `${item} `,
        //     idBatizantesForm ? idBatizantesForm[idx] : 'N/D',
        //   );
        // }
      });
    }

    if (!id || id === '') {
      api
        .post(
          '/batizantes-sala/',
          {
            payload: encrypt(
              JSON.stringify({
                id_batismo,
                nome,
                sexo,
                estado_civil: estado_civil || undefined,
                data_nascimento,
                celular: celular
                  ? celular
                      .replace('(', '')
                      .replace(')', '')
                      .replace('-', '')
                      .replace(' ', '')
                  : undefined,
                email: email || undefined,
                data_batismo,
                cep: cep ? cep.replace('.', '').replace('-', '') : undefined,
                endereco: endereco || undefined,
                numero: numero || undefined,
                complemento: complemento || undefined,
                bairro: bairro || undefined,
                cidade: cidade || undefined,
                estado: estado || undefined,
                ibge: ibge || undefined,
                batismo_conjunto,
                fez_casa_paz,
                id_lider_casa_paz: id_lider_casa_paz || undefined,
                lider_casa_paz: lider_casa_paz || undefined,
                frequenta_celula,
                id_lider_celula: id_lider_celula || undefined,
                lider_celula: lider_celula || undefined,
                nome_celula: nome_celula || undefined,
                observacao: observacao || undefined,
                nomes_batismo_conjunto: nomes_batismo_conjunto || undefined,
                religiao_veio: religiao_veio || undefined,
                modo_casa_paz: modo_casa_paz || undefined,
                dia_horario: dia_horario || undefined,
                domingo_manha,
                domingo_tarde,
                domingo_noite,
                segunda_manha,
                segunda_tarde,
                segunda_noite,
                terca_manha,
                terca_tarde,
                terca_noite,
                quarta_manha,
                quarta_tarde,
                quarta_noite,
                quinta_manha,
                quinta_tarde,
                quinta_noite,
                sexta_manha,
                sexta_tarde,
                sexta_noite,
                sabado_manha,
                sabado_tarde,
                sabado_noite,
                batizantes_juntos: batizantes_juntos || undefined,
                nome_contato: nome_contato || undefined,
                telefone_contato: telefone_contato || undefined,
                facebook: facebook || undefined,
                instagram: instagram || undefined,
                nome_voluntario: (voluntario && voluntario.nome) || undefined,
                celular_voluntario:
                  (voluntario && voluntario.celular) || undefined,
                pre_cadastro: true,
              }),
            ),
          },
          {
            headers: {
              activity: encrypt('Inclusão de Batizante'),
            },
          },
        )
        .then(response => {
          const content = decrypt<IBatizante>(response.data);
          const batizanteFormatado = {
            ...content,
            dataNascimentoFormatted: content.data_nascimento
              ? format(
                  parseISO(content.data_nascimento.toString()),
                  'dd/MM/yyyy',
                )
              : '',
            dataBatismoFormatted: content.data_batismo
              ? format(parseISO(content.data_batismo.toString()), 'dd/MM/yyyy')
              : '',
            estadoCivilFormatted: estados_civis.find(
              ec => ec.id === content.estado_civil,
            )?.title,
            celularFormatted: content.celular
              ? formatarCelular(content.celular)
              : '',
          };

          setRecords([...records, batizanteFormatado]);
          resetForm();
          setRecordForEdit(null);
          setOpenPopup(false);
          addToast({
            type: 'success',
            title: 'Gravado com sucesso!',
          });
          const dados = {
            nome: content.nome?.toUpperCase(),
            qrcode: encryptUUID(content.id),
            tipo: 'etiqueta',
          };
          imprimirEtiqueta(dados);
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o Batizante!',
            description: 'Ocorreu um erro ao tentar adicionar o Batizante.',
          });
        });
    } else {
      api
        .put(
          `/batizantes-sala/${data.id}`,
          {
            payload: encrypt(
              JSON.stringify({
                nome,
                sexo,
                estado_civil,
                data_nascimento,
                celular: celular
                  ? celular
                      .replace('(', '')
                      .replace(')', '')
                      .replace('-', '')
                      .replace(' ', '')
                  : undefined,
                email: email || undefined,
                data_batismo,
                cep: cep ? cep.replace('.', '').replace('-', '') : undefined,
                endereco,
                numero,
                complemento: complemento || undefined,
                bairro,
                cidade,
                estado,
                ibge,
                batismo_conjunto,
                fez_casa_paz,
                id_lider_casa_paz: id_lider_casa_paz || undefined,
                lider_casa_paz: lider_casa_paz || undefined,
                frequenta_celula,
                id_lider_celula: id_lider_celula || undefined,
                lider_celula: lider_celula || undefined,
                nome_celula: nome_celula || undefined,
                observacao: observacao || undefined,
                nomes_batismo_conjunto: nomes_batismo_conjunto || undefined,
                religiao_veio: religiao_veio || undefined,
                modo_casa_paz: modo_casa_paz || undefined,
                dia_horario: dia_horario || undefined,
                domingo_manha,
                domingo_tarde,
                domingo_noite,
                segunda_manha,
                segunda_tarde,
                segunda_noite,
                terca_manha,
                terca_tarde,
                terca_noite,
                quarta_manha,
                quarta_tarde,
                quarta_noite,
                quinta_manha,
                quinta_tarde,
                quinta_noite,
                sexta_manha,
                sexta_tarde,
                sexta_noite,
                sabado_manha,
                sabado_tarde,
                sabado_noite,
                batizantes_juntos: batizantes_juntos || undefined,
                nome_contato: nome_contato || undefined,
                telefone_contato: telefone_contato || undefined,
                facebook: facebook || undefined,
                instagram: instagram || undefined,
                nome_voluntario: (voluntario && voluntario.nome) || undefined,
                celular_voluntario:
                  (voluntario && voluntario.celular) || undefined,
                pre_cadastro: true,
              }),
            ),
          },
          {
            headers: {
              activity: encrypt('Alteração de Batizante'),
            },
          },
        )
        .then(response => {
          const recordsGetted = records;
          const content = decrypt<IBatizante>(response.data);
          const recordUpdatedIndex = records.findIndex(r => r.id === data.id);
          const batizanteFormatado = {
            ...content,
            dataNascimentoFormatted: content.data_nascimento
              ? format(
                  parseISO(content.data_nascimento.toString()),
                  'dd/MM/yyyy',
                )
              : '',
            dataBatismoFormatted: content.data_batismo
              ? format(parseISO(content.data_batismo.toString()), 'dd/MM/yyyy')
              : '',
            estadoCivilFormatted: estados_civis.find(
              ec => ec.id === content.estado_civil,
            )?.title,
            celularFormatted: content.celular
              ? formatarCelular(content.celular)
              : '',
          };
          recordsGetted[recordUpdatedIndex] = batizanteFormatado;
          setRecords(recordsGetted);
          resetForm();
          setRecordForEdit(null);
          setOpenPopup(false);
          addToast({
            type: 'success',
            title: 'Gravado com sucesso!',
          });
          // const dados = {
          //   nome: content.nome?.toUpperCase(),
          //   qrcode: encryptUUID(content.id),
          //   tipo: 'etiqueta',
          // };
          // imprimirEtiqueta(dados);
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'Erro ao alterar o Batizante!',
            description: 'Ocorreu um erro ao tentar alterar o Batizante.',
          });
        });
    }
  };

  const updateFoto = (id_batizante: string, new_url: string): void => {
    if (id_batizante && new_url) {
      const indexRecordUpdate = records.findIndex(r => r.id === id_batizante);
      const recordsDiscipulos = records;
      if (indexRecordUpdate >= 0) {
        recordsDiscipulos[indexRecordUpdate].foto_url = new_url;
        setRecords(recordsDiscipulos);
      }
    }
  };

  const renderPerson = (person: IBatizante, idx: any): any => {
    return (
      <li key={idx}>
        <ItemLista>
          <div>
            <div>
              <p>Nome:</p>
              <span>{person.nome}</span>
            </div>
            <div>
              <p>Data Nascimento:</p>
              <span>{person.dataNascimentoFormatted}</span>
            </div>
            <div>
              <p>Celular:</p>
              <span>{person.celularFormatted}</span>
            </div>
            <div>
              <p>E-mail:</p>
              <span>{person.email}</span>
            </div>
            <div>
              <p>Estado Civil:</p>
              <span>{person.estadoCivilFormatted}</span>
            </div>
            <div>
              <p>Data Batismo:</p>
              <span>{person.dataBatismoFormatted}</span>
            </div>
            <ItemBotoes>
              {editar && (
                <ActionButton
                  type="button"
                  width="25px"
                  height="25px"
                  backgroundColor="#999"
                  fontColor="#000"
                  onClick={() => {
                    setActionDialog({
                      isOpen: true,
                      title: `Confirmar impressão da etiqueta?`,
                      subTitle: 'Verifique a impressora',
                      onConfirm: () => {
                        setActionDialog({
                          ...actionDialog,
                          isOpen: false,
                        });
                        const dados = {
                          nome: person.nome?.toUpperCase(),
                          qrcode: person.id ? encryptUUID(person.id) : '',
                          tipo: 'etiqueta',
                        };
                        imprimirEtiqueta(dados);
                      },
                    });
                  }}
                >
                  <PrintOutlinedIcon fontSize="small" />
                </ActionButton>
              )}
              {editar && (
                <ActionButton
                  type="button"
                  width="25px"
                  height="25px"
                  backgroundColor="#999"
                  fontColor="#000"
                  onClick={() => {
                    openInPopup(person);
                  }}
                >
                  <EditOutlinedIcon fontSize="small" />
                </ActionButton>
              )}
            </ItemBotoes>
          </div>
        </ItemLista>
      </li>
    );
  };

  // const renderPersonView = (person: IBatizante, idx: any): any => {
  //   return (
  //     <li key={idx}>
  //       <ItemListaView>
  //         <>
  //           <div>
  //             <div>
  //               <p>Nome:</p>
  //               <span>{person.nome}</span>
  //             </div>
  //             <div>
  //               <p>Data Nascimento:</p>
  //               <span>{person.dataNascimentoFormatted}</span>
  //             </div>
  //             <div>
  //               <p>Celular:</p>
  //               <span>{person.celularFormatted}</span>
  //             </div>
  //             <div>
  //               <p>E-mail:</p>
  //               <span>{person.email}</span>
  //             </div>
  //             <div>
  //               <p>Estado Civil:</p>
  //               <span>{person.estadoCivilFormatted}</span>
  //             </div>
  //             <div>
  //               <p>Data Batismo:</p>
  //               <span>{person.dataBatismoFormatted}</span>
  //             </div>
  //             <img src={person.foto_url} alt={person.nome} height="70px" />
  //           </div>
  //           <div>
  //             <ItemBotoes>
  //               {listar && (
  //                 <ActionButton
  //                   type="button"
  //                   width="25px"
  //                   height="25px"
  //                   backgroundColor="#999"
  //                   fontColor="#000"
  //                   onClick={() => {
  //                     openInPopup(person);
  //                   }}
  //                 >
  //                   <EditOutlinedIcon fontSize="small" />
  //                 </ActionButton>
  //               )}
  //             </ItemBotoes>
  //           </div>
  //         </>
  //       </ItemListaView>
  //     </li>
  //   );
  // };

  return (
    <>
      {!voluntario && (
        <>
          <BackgroundErro />
          <ContainerErro>
            <ContentErro>
              <AnimationContainerErro>
                <img src={logoImg} height="200px" alt="IEQ Lagoinha" />
                <h1>Voluntário não informado</h1>
              </AnimationContainerErro>
            </ContentErro>
          </ContainerErro>
        </>
      )}
      {(show || isAdmin) && voluntario && serverConection && (
        <>
          <Grid container className={classes.root}>
            <Grid item xs={6}>
              <Typography
                variant="h4"
                gutterBottom
                noWrap
                className={classes.titleHead}
              >
                Pré-Cadastro
              </Typography>
              <Typography
                variant="h5"
                gutterBottom
                noWrap
                className={classes.titleHeadH5}
              >
                Batismo Abril/2025
              </Typography>
              <Typography
                variant="h5"
                gutterBottom
                noWrap
                className={classes.titleHeadH5}
              >
                {voluntario &&
                  `Preenchido por: ${voluntario?.nome.trim()} (${
                    voluntario?.celularFormatted
                  })`}
              </Typography>
            </Grid>
            {adicionar && (
              <Grid item xs={6}>
                <div className={classes.buttonContainer}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setOpenPopup(true);
                      setRecordForEdit(null);
                    }}
                  >
                    Adicionar
                  </Button>
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <Toolbar>
                <TextField
                  id="search-field"
                  label="Pesquisar"
                  onChange={handleSearch}
                  className={classes.searchInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                />
              </Toolbar>
            </Grid>
          </Grid>
          <Grid className={classes.lista}>
            <Grid item xs={12}>
              {loading || !listar ? (
                <div
                  style={{
                    display: 'flex',
                    marginTop: '40px',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <div className={classes.desktop}>
                    <TblContainer>
                      <TblHead />
                      <TableBody>
                        {recordsAfterPagingAndSorting().map((item: any) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.nome}</TableCell>
                            <TableCell className={classes.cell}>
                              {item.dataNascimentoFormatted}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.celularFormatted}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.email}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.estadoCivilFormatted}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {item.dataBatismoFormatted}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {editar && (
                                <Button
                                  color="default"
                                  onClick={() => {
                                    setActionDialog({
                                      isOpen: true,
                                      title: `Confirmar impressão da etiqueta?`,
                                      subTitle: 'Verifique a impressora',
                                      onConfirm: () => {
                                        setActionDialog({
                                          ...actionDialog,
                                          isOpen: false,
                                        });
                                        const dados = {
                                          nome: item.nome?.toUpperCase(),
                                          qrcode: item.id
                                            ? encryptUUID(item.id)
                                            : '',
                                          tipo: 'etiqueta',
                                        };
                                        imprimirEtiqueta(dados);
                                      },
                                    });
                                  }}
                                >
                                  <PrintOutlinedIcon fontSize="small" />
                                </Button>
                              )}
                              {editar && (
                                <Button
                                  color="default"
                                  onClick={() => {
                                    openInPopup(item);
                                  }}
                                >
                                  <EditOutlinedIcon fontSize="small" />
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </TblContainer>
                    <TblPagination />
                  </div>
                  <div className={classes.mobile}>
                    <ul>
                      <FlatList
                        list={records}
                        renderItem={renderPerson}
                        renderWhenEmpty={() => (
                          <div
                            style={{ textAlign: 'center', marginTop: '30px' }}
                          >
                            Nenhum Batizante!
                          </div>
                        )}
                        sortBy={[
                          {
                            key: 'nomeSA',
                            ascending: true,
                            caseInsensitive: true,
                          },
                        ]}
                        search={{
                          by: [
                            'nomeSA',
                            'nome',
                            'celular',
                            'email',
                            'estadoCivilFormatted',
                          ],
                          term: search,
                          caseInsensitive: true,
                        }}
                      />
                    </ul>
                  </div>
                </>
              )}
              <Popup
                title="Pré-Cadastro de Batizante"
                openPopup={openPopup}
                setOpenPopup={setOpenPopup}
              >
                <BatizanteForm
                  recordForEdit={recordForEdit}
                  addOrEdit={addOrEdit}
                  updateFoto={updateFoto}
                  discipulos={discipulos}
                  cidades={cidades}
                  estados={estados}
                />
              </Popup>
            </Grid>
          </Grid>
        </>
      )}
      {!show && !isAdmin && serverConection && (
        <>
          <BackgroundErro />
          <ContainerErro>
            <ContentErro>
              <AnimationContainerErro>
                <img src={logoImg} height="200px" alt="IEQ Lagoinha" />
                <h1>Acesso negado!</h1>
                <h1>Você está fora da Igreja</h1>
              </AnimationContainerErro>
            </ContentErro>
          </ContainerErro>
        </>
      )}
      {!serverConection && (
        <>
          <BackgroundErro />
          <ContainerErro>
            <ContentErro>
              <AnimationContainerErro>
                <img src={logoImg} height="200px" alt="IEQ Lagoinha" />
                <h1>Sem internet!</h1>
                <h1>Sem acesso ao servidor</h1>
              </AnimationContainerErro>
            </ContentErro>
          </ContainerErro>
        </>
      )}
      <Popup
        title="Voluntário"
        closeDisabled
        openPopup={openPopupVoluntario}
        setOpenPopup={setOpenPopupVoluntario}
      >
        <VoluntarioForm addVoluntario={addVoluntario} />
      </Popup>
      <ActionDialog
        open={actionDialog.isOpen}
        actionDialog={actionDialog}
        setActionDialog={setActionDialog}
      />
    </>
  );
};

export default BatizantePreCadastroPage;
